define('ember-easy-form-extensions/components/form-submission-button', ['exports', 'ember', 'ember-easy-form-extensions/templates/components/form-submission-button'], function (exports, _ember, _emberEasyFormExtensionsTemplatesComponentsFormSubmissionButton) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({

    /* Options */

    action: null,
    className: 'button',
    disabled: false,
    text: null,
    type: 'button',
    layout: _emberEasyFormExtensionsTemplatesComponentsFormSubmissionButton['default'],

    /* Properties */

    attributeBindings: ['dataTest:data-test', 'disabled', 'type'],
    classNameBindings: ['className'],
    tagName: 'button',

    dataTest: computed('action', function () {
      var action = this.get('action') || '';
      var dasherizedAction = _ember['default'].String.dasherize(action);

      return 'button-for-' + dasherizedAction;
    }),

    /* Methods */

    click: function click(event) {
      event.preventDefault();
      event.stopPropagation();

      this.sendAction();
    }
  });
});