define('ember-easy-form-extensions/components/input-group', ['exports', 'ember-easy-form-extensions/utils/default-for', 'ember', 'ember-easy-form-extensions/utils/humanize'], function (exports, _emberEasyFormExtensionsUtilsDefaultFor, _ember, _emberEasyFormExtensionsUtilsHumanize) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = _ember['default'].Component.extend({

    /* Options */

    classNames: ['input-wrapper'],
    className: 'input-wrapper',
    hint: null,
    pathToInputPartials: 'form-inputs',
    property: null,
    newlyValidDuration: 3000,

    /* Input attributes */

    collection: null,
    content: null,
    optionValuePath: 'content',
    optionLabelPath: 'content',
    selection: null,
    multiple: null,
    name: computed.oneWay('property'),
    placeholder: null,
    prompt: null,
    disabled: false,

    /* Properties */

    attributeBindings: ['dataTest:data-test'],
    bindingForValue: null, // Avoid xBinding naming convention
    classNameBindings: ['validityClass'],
    formControls: null,
    isInvalid: computed.not('isValid'),
    isNewlyValid: false,
    isValid: true,
    modelPath: computed.oneWay('formControls.modelPath'),
    registerAction: 'registerInputGroup',
    showError: false,
    unregisterAction: 'unregisterInputGroup',
    value: null,

    dataTest: computed('property', function () {
      var property = this.get('property');
      var dasherizedProperty = _ember['default'].String.dasherize(property);

      return 'input-wrapper-for-' + dasherizedProperty;
    }),

    formController: computed(function () {
      var hasFormController = this.nearestWithProperty('formController');

      return hasFormController.get('formController');
    }),

    inputId: computed(function () {
      return this.get('elementId') + '-input';
    }),

    inputPartial: computed('type', function () {
      var _getProperties = this.getProperties(['pathToInputPartials', 'type']);

      var pathToInputPartials = _getProperties.pathToInputPartials;
      var type = _getProperties.type;

      /* Remove leading and trailing slashes for consistency */

      var dir = pathToInputPartials.replace(/^\/|\/$/g, '');

      if (!!_ember['default'].getOwner(this).lookup('template:' + dir + '/' + type)) {
        return dir + '/' + type;
      } else {
        return dir + '/default';
      }
    }),

    isInputWrapper: computed(function () {
      return true;
    }).readOnly(),

    label: computed('property', function () {
      var property = (0, _emberEasyFormExtensionsUtilsDefaultFor['default'])(this.get('property'), '');

      return (0, _emberEasyFormExtensionsUtilsHumanize['default'])(property);
    }),

    propertyWithModel: computed('property', 'modelPath', function () {
      var _getProperties2 = this.getProperties(['modelPath', 'property']);

      var modelPath = _getProperties2.modelPath;
      var property = _getProperties2.property;

      if (modelPath) {
        return modelPath + '.' + property;
      } else {
        return property;
      }
    }),

    type: computed('property', function () {
      var property = this.get('property');

      var type = 'text';

      if (this.get('content')) {
        type = 'select';
      } else if (property.match(/password/)) {
        type = 'password';
      } else if (property.match(/email/)) {
        type = 'email';
      } else if (property.match(/url/)) {
        type = 'url';
      } else if (property.match(/color/)) {
        type = 'color';
      } else if (property.match(/^tel/) || property.match(/^phone/)) {
        type = 'tel';
      } else if (property.match(/search/)) {
        type = 'search';
      } else {
        var value = this.get('value');

        if (typeOf(value) === 'number') {
          type = 'number';
        } else if (typeOf(value) === 'date') {
          type = 'date';
        } else if (typeOf(value) === 'boolean') {
          type = 'checkbox';
        }
      }

      return type;
    }),

    validityClass: computed('isValid', function () {
      var className = this.get('className');

      var modifier = undefined;

      if (this.get('isValid')) {
        modifier = 'valid';
      } else {
        modifier = 'error';
      }
      return className + '-' + modifier;
    }),

    focusOut: function focusOut() {
      this.send('showError');
      return true;
    },

    /* Actions */

    actions: {

      //Issue from here: https://github.com/sir-dunxalot/ember-easy-form-extensions/issues/41
      showError: function showError() {
        if (!this.get('isDestroying')) {
          this.set('showError', true);
        }
      },

      setGroupAsInvalid: function setGroupAsInvalid() {
        if (!this.get('isDestroying')) {
          this.set('isValid', false);
        }
      },

      setGroupAsValid: function setGroupAsValid() {
        if (!this.get('isDestroying')) {
          this.set('isValid', true);
        }
      }
    },

    /* Public methods - avoid xBinding syntax */

    listenForNewlyValid: observer('isValid', function () {
      if (this.get('isValid')) {
        this.set('isNewlyValid', true);
      }

      run.later(this, function () {
        if (!this.get('isDestroying')) {
          this.set('isNewlyValid', false);
        }
      }, this.get('newlyValidDuration'));
    }),

    setBindingForValue: on('init', function () {
      _ember['default'].assert('You must set a property attribute on the {{input-group}} component', this.get('property'));
    }),

    setFormControls: on('init', function () {
      this.set('formControls', this.nearestWithProperty('isFormControls'));
      var propertyWithModel = this.get('propertyWithModel');
      var binding = _ember['default'].computed.alias('formController.' + propertyWithModel);
      this.set('value', binding);

      this.set('bindingForValue', binding);
    }),

    /* Private methods */

    _registerWithFormController: on('init', function () {
      this.sendAction('registerAction', this);
    }),

    _unregisterWithFormController: on('willDestroyElement', function () {
      this.sendAction('unregisterAction', this);
    })

  });
});