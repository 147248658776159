define('ember-ajax/-private/utils/parse-response-headers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = parseResponseHeaders;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  const CRLF = exports.CRLF = '\u000d\u000a';

  function parseResponseHeaders(headersString) {
    const headers = {};

    if (!headersString) {
      return headers;
    }

    return headersString.split(CRLF).reduce((hash, header) => {
      var _header$split = header.split(':'),
          _header$split2 = _toArray(_header$split);

      let field = _header$split2[0],
          value = _header$split2.slice(1);

      field = field.trim();
      value = value.join(':').trim();

      if (value) {
        hash[field] = value;
      }

      return hash;
    }, headers);
  }
});