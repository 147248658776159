define('ember-angle-bracket-invocation-polyfill/helpers/-link-to-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function linkToParams(_params, { route, model, models, query }) {
    let params = [];

    if (route) {
      params.push(route);
    }

    if (model) {
      params.push(model);
    }

    if (models) {
      params.push(...models);
    }

    if (query) {
      params.push({
        isQueryParams: true,
        values: query
      });
    }

    return params;
  }

  exports.default = Ember.Helper.helper(linkToParams);
});