define('ember-easy-form-extensions/mixins/controllers/form', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Mixin.create(_emberValidations['default'], {

    /* Properties */

    formIsSubmitted: false,

    editing: computed(function () {
      return this.toString().indexOf('/edit:') > -1;
    }),

    hasFormMixin: computed(function () {
      return true;
    }).readOnly(),

    'new': computed(function () {
      return this.toString().indexOf('/new:') > -1;
    }),

    /* Actions */

    actions: {

      /* Actions for child input groups */

      registerInputGroup: function registerInputGroup(inputGroupComponent) {
        this.on('submission', function () {
          inputGroupComponent.send('showError');
        });
      },

      unregisterInputGroup: function unregisterInputGroup(inputGroupComponent) {
        this.off('submission', function () {
          inputGroupComponent.send('showError');
        });
      },

      /* Form submission actions */

      cancel: function cancel() {
        this.set('formIsSubmitted', true);
        this._eventHandler('cancel');
      },

      'delete': function _delete() {
        this.set('formIsSubmitted', true);
        this._eventHandler('delete');
      },

      /* Show validation errors on submit click */

      save: function save() {
        this.set('formIsSubmitted', true);
        this.trigger('submission');
        this._eventHandler('save');
      }

    },

    /* Methods */

    resetForm: on('routeDidTransition', function () {
      this.resetSubmission();

      /* Add logic for resetting anything to do with
      input here */
    }),

    resetSubmission: function resetSubmission() {
      this.set('formIsSubmitted', false);
    },

    showServerError: function showServerError() /* xhr */{
      this.resetSubmission();
    },

    validateThenSave: function validateThenSave() {
      var _this = this;

      function resolve() {
        _ember['default'].assert('You need to specify a save method on this controller', typeof _this.save === 'function');

        _this.save();
      }

      function reject() {
        _this.set('formIsSubmitted', false);
      }

      /* If there is a custom validations method, resolve it */

      if (this.runCustomValidations) {
        var customValidationsPromise = this.runCustomValidations();

        if (!customValidationsPromise || !customValidationsPromise.then) {
          _ember['default'].assert('runCustomValidations() must return a promise (e.g. return new Ember.RSVP.Promise(...)).');
        }

        customValidationsPromise.then(resolve, reject);
      } else {

        /* Else save with normal ember-validations checks */

        if (!this.get('isValid')) {
          reject();
        } else {
          resolve();
        }
      }
    },

    /* Private methods */

    _eventHandler: function _eventHandler(type) {
      var capitalizedType = _ember['default'].String.capitalize(type);
      var handlerMethodName = 'before' + capitalizedType;
      var handler = this[handlerMethodName];

      function isFunction(key) {
        return _ember['default'].typeOf(key) === 'function';
      }

      /* If event is save, method is renamed */

      if (type === 'save') {
        type = 'validateThenSave';
      }

      var method = this[type];

      _ember['default'].assert('You need to specify a ' + type + ' method on this controller', method && isFunction(method));

      /* If handler exists, resolve the promise then call
      the method... */

      if (handler) {
        _ember['default'].assert(handlerMethodName + '() must be a function', isFunction(handler));

        var handlerPromise = handler();

        if (!handlerPromise.then) {
          _ember['default'].assert('handler() must return a promise (e.g. return new Ember.RSVP.Promise(...))');
        }

        handlerPromise.then((function () {
          this[type]();
        }).bind(this));

        /* ...Else, just call the method */
      } else {
          this[type]();
        }
    }

  });
});