define('ember-easy-form-extensions/components/form-controls', ['exports', 'ember', 'ember-easy-form-extensions/utils/observers/soft-assert'], function (exports, _ember, _emberEasyFormExtensionsUtilsObserversSoftAssert) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['legend'],
    className: 'controls',
    classNameBindings: ['className'],
    legend: null,
    modelPath: 'model',
    tagName: 'fieldset',
    checkForLegend: (0, _emberEasyFormExtensionsUtilsObserversSoftAssert['default'])('legend'),

    isFormControls: computed(function () {
      return true;
    }).readOnly()

  });
});