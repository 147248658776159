define('ember-easy-form-extensions/helpers/capitalize-string', ['exports', 'ember'], function (exports, _ember) {
  exports.capitalizeString = capitalizeString;

  function capitalizeString(params) {
    var string = params[0] || '';

    return _ember['default'].String.capitalize(string);
  }

  exports['default'] = _ember['default'].Helper.helper(capitalizeString);
});