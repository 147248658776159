define('ember-easy-form-extensions/mixins/components/form-submission-class-name', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Mixin.create({
    className: null,
    classNameBindings: ['submissionClassName'],

    submissionClassName: computed('className', 'formIsSubmitted', function () {
      var className = this.get('className');
      var formIsSubmitted = this.get('formIsSubmitted');

      if (formIsSubmitted) {
        return className + '-submitted';
      } else {
        return null;
      }
    })
  });
});