define('ember-easy-form-extensions/mixins/controllers/conditional-validations', ['exports', 'ember'], function (exports, _ember) {
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = _ember['default'].Mixin.create({
    revalidateFor: [],

    forEachRevalidator: function forEachRevalidator(callback) {
      var revalidateFor = this.get('revalidateFor');

      if (revalidateFor.length) {
        revalidateFor.forEach(function (property) {
          callback(property);
        });
      }
    },

    /* TODO - update to new controller hooks with routable
    components release. At that time, deprecate the routing-events
    initializer */

    _revalidate: on('routeDidTransition', function () {
      var validateExists = typeOf(this.validate) === 'function';

      _ember['default'].assert('No validate() method detected. You must use the conditional validations mixin with the form mixin.', validateExists);

      this.forEachRevalidator((function (property) {
        this.addObserver(property, this.validate);
      }).bind(this));
    }),

    _removeRevalidationObservers: on('routeWillTransition', function () {
      this.forEachRevalidator((function (property) {
        this.removeObserver(property, this.validate);
      }).bind(this));
    })
  });
});