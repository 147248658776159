define('ember-stripe-elements/components/stripe-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ember-stripe-element'],

    autofocus: false,
    options: {},
    stripeElement: null,
    stripeError: null,
    type: null, // Set in components that extend from `stripe-element`

    stripev3: Ember.inject.service(),
    elements: Ember.computed(function () {
      return Ember.get(this, 'stripev3.elements')();
    }),

    didInsertElement() {
      this._super(...arguments);

      // Fetch user options
      let options = Ember.get(this, 'options');

      // Fetch `type` set by child component
      let type = Ember.get(this, 'type');

      // `stripeElement` instead of `element` to distinguish from `this.element`
      let stripeElement = Ember.get(this, 'elements').create(type, options);

      // Mount the Stripe Element onto the mount point
      stripeElement.mount(this.element.querySelector('[role="mount-point"]'));

      // Make the element available to the component
      Ember.set(this, 'stripeElement', stripeElement);

      // Set the event listeners
      this.setEventListeners();
    },

    didRender() {
      this._super(...arguments);
      // Fetch autofocus, set by user
      let autofocus = Ember.get(this, 'autofocus');
      let stripeElement = Ember.get(this, 'stripeElement');
      let $iframe = this.$('iframe')[0];
      if (autofocus && $iframe) {
        $iframe.onload = () => {
          stripeElement.focus();
        };
      }
    },

    didUpdateAttrs() {
      this._super(...arguments);
      Ember.get(this, 'stripeElement').update(Ember.get(this, 'options'));
    },

    willDestroyElement() {
      this._super(...arguments);
      Ember.get(this, 'stripeElement').unmount();
    },

    setEventListeners() {
      let stripeElement = Ember.get(this, 'stripeElement');
      stripeElement.on('ready', event => this.sendAction('ready', stripeElement, event));
      stripeElement.on('blur', event => this.sendAction('blur', stripeElement, event));
      stripeElement.on('focus', event => this.sendAction('focus', stripeElement, event));
      stripeElement.on('change', (...args) => {
        var _args$ = args[0];
        let complete = _args$.complete,
            stripeError = _args$.error;

        this.sendAction('change', stripeElement, ...args);

        if (complete) {
          this.sendAction('complete', stripeElement);
        } else if (stripeError) {
          this.sendAction('error', stripeError);
        }

        Ember.set(this, 'stripeError', stripeError);
      });
    }
  });
});