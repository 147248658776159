define('ember-stripe-elements/services/stripev3', ['exports', 'ember-stripe-elements/utils/load-script'], function (exports, _loadScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    config: null,
    didConfigure: false,
    didLoad: false,

    lazyLoad: Ember.computed.readOnly('config.lazyLoad'),
    mock: Ember.computed.readOnly('config.mock'),
    publishableKey: Ember.computed.readOnly('config.publishableKey'),

    init() {
      this._super(...arguments);

      let lazyLoad = this.get('lazyLoad');
      let mock = this.get('mock');

      if (!lazyLoad || mock) {
        this.configure();
      }
    },

    load() {
      let lazyLoad = this.get('lazyLoad');
      let mock = this.get('mock');
      let shouldLoad = lazyLoad && !mock;

      let doLoad = shouldLoad ? (0, _loadScript.default)("https://js.stripe.com/v3/") : Ember.RSVP.resolve();

      return doLoad.then(() => {
        this.configure();
        this.set('didLoad', true);
      });
    },

    configure() {
      let didConfigure = this.get('didConfigure');

      if (!didConfigure) {
        let publishableKey = this.get('publishableKey');

        var _ref = new Stripe(publishableKey);

        let elements = _ref.elements,
            createToken = _ref.createToken,
            createSource = _ref.createSource,
            retrieveSource = _ref.retrieveSource,
            paymentRequest = _ref.paymentRequest;

        Ember.setProperties(this, { elements, createToken, createSource, retrieveSource, paymentRequest });

        this.set('didConfigure', true);
      }
    }
  });
});