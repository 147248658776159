define('ember-easy-form-extensions/utils/observers/soft-assert', ['exports', 'ember', 'ember-easy-form-extensions/utils/default-for'], function (exports, _ember, _emberEasyFormExtensionsUtilsDefaultFor) {
  exports['default'] = softAssert;

  function softAssert(dependentKey) {
    var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    var eventName = (0, _emberEasyFormExtensionsUtilsDefaultFor['default'])(options.eventName, 'init');

    return _ember['default'].on(eventName, function () {
      var value = (0, _emberEasyFormExtensionsUtilsDefaultFor['default'])(this.get(dependentKey), '');

      if (!value) {
        var _name = this.get('className'); // TODO - Find a better way to identify component constructor

        //Ember.warn(`You failed to pass a ${dependentKey} property to ${name}`);

        if (options.onTrue) {
          options.callbacks.onTrue().bind(this);
        }
      } else if (options.onFalse) {
        options.callbacks.onFalse().bind(this);
      }
    });
  }
});
/**
Checks whether a property is present on a class and shows a warning to the developer when it is not.

Options can be pased as a second parameter:

```js
Ember.Component.extend({
  checkForDescription: softAssert('descriptions', {
    eventName: 'didInsertElement', // Defaults to 'init'
    onTrue: function() {
      this.set('hasCorrectProperties', true);
    },
    onFalse: function() {
      this.set('hasCorrectProperties', false);
    }
  });
});
```

@method Utils.computed.softAssert
@param {String} dependentKey The name of the Ember property to observe
@param {Object} options An object containing options for your assertion
*/