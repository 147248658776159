define('ember-crumbly/components/bread-crumbs', ['exports', 'ember-copy', 'ember-crumbly/templates/components/bread-crumbs'], function (exports, _emberCopy, _breadCrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const bool = Ember.computed.bool,
        readOnly = Ember.computed.readOnly;
  exports.default = Ember.Component.extend({
    routerService: Ember.inject.service('router'),
    layout: _breadCrumbs.default,
    tagName: 'ol',
    linkable: true,
    reverse: false,
    classNameBindings: ['breadCrumbClass'],
    hasBlock: bool('template').readOnly(),
    currentUrl: readOnly('routerService.currentURL'),
    currentRouteName: readOnly('routerService.currentRouteName'),

    routeHierarchy: Ember.computed('currentUrl', 'currentRouteName', 'reverse', {
      get() {
        Ember.get(this, 'currentUrl');
        const currentRouteName = Ember.getWithDefault(this, 'currentRouteName', false);

        (false && !(currentRouteName) && Ember.assert('[ember-crumbly] Could not find a current route', currentRouteName));


        const routeNames = currentRouteName.split('.');
        const filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
        const crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

        return Ember.get(this, 'reverse') ? crumbs.reverse() : crumbs;
      }
    }).readOnly(),

    breadCrumbClass: Ember.computed('outputStyle', {
      get() {
        let className = 'breadcrumb';
        const outputStyle = Ember.getWithDefault(this, 'outputStyle', '');
        if (Ember.isPresent(outputStyle)) {
          Ember.deprecate('outputStyle option will be deprecated in the next major release', false, { id: 'ember-crumbly.outputStyle', until: '2.0.0' });
        }
        const lowerCaseOutputStyle = outputStyle.toLowerCase();

        if (lowerCaseOutputStyle === 'foundation') {
          className = 'breadcrumbs';
        }

        return className;
      }
    }).readOnly(),

    _guessRoutePath(routeNames, name, index) {
      const routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        let path = `${name}.index`;

        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },

    _filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(name => !(name === 'index' || name === 'loading'));
    },

    _lookupRoute(routeName) {
      return Ember.getOwner(this).lookup(`route:${routeName}`);
    },

    _lookupBreadCrumb(routeNames, filteredRouteNames) {
      const defaultLinkable = Ember.get(this, 'linkable');
      const pathLength = filteredRouteNames.length;
      const breadCrumbs = Ember.A();

      filteredRouteNames.map((name, index) => {
        let path = this._guessRoutePath(routeNames, name, index);
        const route = this._lookupRoute(path);
        const isHead = index === 0;
        const isTail = index === pathLength - 1;

        const crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;

        (false && !(route) && Ember.assert(`[ember-crumbly] \`route:${path}\` was not found`, route));


        const multipleBreadCrumbs = route.get('breadCrumbs');

        if (multipleBreadCrumbs) {
          multipleBreadCrumbs.forEach(breadCrumb => {
            breadCrumbs.pushObject(breadCrumb);
          });
        } else {
          let breadCrumb = (0, _emberCopy.copy)(Ember.getWithDefault(route, 'breadCrumb', {
            title: Ember.String.classify(name)
          }));

          if (Ember.typeOf(breadCrumb) === 'null') {
            return;
          } else {
            if (Ember.isPresent(breadCrumb.path)) {
              path = breadCrumb.path;
            }

            Ember.setProperties(breadCrumb, {
              path,
              isHead,
              isTail,
              linkable: breadCrumb.hasOwnProperty('linkable') ? breadCrumb.linkable : crumbLinkable
            });
          }

          breadCrumbs.pushObject(breadCrumb);
        }
      });

      return Ember.A(breadCrumbs.filter(breadCrumb => Ember.typeOf(breadCrumb) !== 'undefined'));
    }
  });
});