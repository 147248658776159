define('ember-cli-react/utils/has-block', ['exports', 'ember-cli-react/utils/ember-version-info'], function (exports, _emberVersionInfo2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = hasBlock;

  var _emberVersionInfo = (0, _emberVersionInfo2.default)();

  const major = _emberVersionInfo.major,
        minor = _emberVersionInfo.minor,
        isGlimmer = _emberVersionInfo.isGlimmer;


  let hasBlockSymbol;

  try {
    if (major > 3 || major == 3 && minor >= 1) {
      // Ember-glimmer moved to TypeScript since v3.1
      // Do nothing since the symbol is not exported
    } else if (isGlimmer) {
      hasBlockSymbol = Ember.__loader.require('ember-glimmer/component')['HAS_BLOCK'];
    } else {
      hasBlockSymbol = Ember.__loader.require('ember-htmlbars/component')['HAS_BLOCK'];
    }
  } catch (e) {}
  // Fallback to use runtime check


  // NOTE: I really don't know how to test this
  function hasBlock(emberComponent) {
    // Since Glimmer moved to TypeScript, we can't get the symbol.
    // This is a terrible but working way to get the value.
    if (!hasBlockSymbol) {
      const regex = /HAS_BLOCK/;
      hasBlockSymbol = Object.getOwnPropertyNames(emberComponent).find(key => regex.test(key));
    }

    return Ember.get(emberComponent, hasBlockSymbol);
  }
});