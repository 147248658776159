define('ember-easy-form-extensions/components/error-field', ['exports', 'ember', 'ember-easy-form-extensions/templates/components/error-field'], function (exports, _ember, _emberEasyFormExtensionsTemplatesComponentsErrorField) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Component.extend({

    /* Options */

    className: 'error',
    label: computed.oneWay('property'),
    property: null,

    /* Properties */

    bindingForErrors: null,
    canAndShouldShowError: computed.and('showError', 'errors.length'),
    classNameBindings: ['className', 'showError'],
    errors: null,
    invalidAction: 'setGroupAsInvalid',
    layout: _emberEasyFormExtensionsTemplatesComponentsErrorField['default'],
    tagName: 'span',
    validAction: 'setGroupAsValid',
    showError: false,

    text: computed('errors.firstObject', 'label', function () {
      var error = this.get('errors.firstObject');
      var label = this.get('label');

      return label + ' ' + error;
    }),

    formController: computed(function () {
      var hasFormController = this.nearestWithProperty('formController');

      return hasFormController.get('formController');
    }),

    /* Methods */

    // TODO - update from observer to event when possible

    notifyChangeInValidity: observer('canAndShouldShowError', function () {
      var actionProperty = this.get('canAndShouldShowError') ? 'invalidAction' : 'validAction';
      _ember['default'].run.once(this, 'sendAction', actionProperty);
    }),

    addBindingForErrors: on('init', function () {
      var property = this.get('property');

      _ember['default'].assert('You must set a property attribute on the {{error-field}} component', property);

      var formController = this.get('formController');
      var validations = formController.get('validations');
      var validationsForProperty = !!validations[property];

      if (validationsForProperty && !this.get('bindingForErrors')) {
        var errorPath = 'formController.errors.' + property;
        var binding = _ember['default'].computed.readOnly(errorPath);

        this.set('errors', binding);
        this.set('bindingForErrors', binding);
      }
    })
  });
});