define('ember-easy-form-extensions/components/form-wrapper', ['exports', 'ember', 'ember-easy-form-extensions/mixins/components/form-submission-class-name'], function (exports, _ember, _emberEasyFormExtensionsMixinsComponentsFormSubmissionClassName) {
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Component.extend(_emberEasyFormExtensionsMixinsComponentsFormSubmissionClassName['default'], {

    /* Options */

    className: 'form',
    novalidate: true,
    autoFocus: true,

    /* Properties */

    attributeBindings: ['novalidate'],
    classNameBindings: ['className'],
    formIsSubmitted: computed.oneWay('formController.formIsSubmitted'),
    tagName: 'form',

    /* Properties */

    /* A shim to enabled use with controller and components
    moving forward */

    formController: _ember['default'].computed(function () {
      // TODO: targetObject is deprecated as of 2.13; this is a bandaid
      var routeController = this.get('_targetObject');

      if (this.get('hasFormMixin')) {
        return this;
      } else if (routeController.get('hasFormMixin')) {
        return routeController;
      } else {
        return null;
      }
    }),

    /* Methods */

    /* Autofocus on the first input.
     TODO - move to form component
    mixin when routeable components land */

    autofocus: on('didInsertElement', function () {
      if (this.get('autoFocus')) {
        var input = this.$().find('input').first();
        if (!_ember['default'].$(input).hasClass('datepicker')) {
          setTimeout(function () {
            input.focus();
          }, 100);
        }
      }
    })

  });
});