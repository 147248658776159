define('ember-cli-react/utils/get-mutable-attributes', ['exports', 'ember-cli-react/utils/ember-version-info'], function (exports, _emberVersionInfo2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getMutableAttributes;

  var _emberVersionInfo = (0, _emberVersionInfo2.default)();

  const major = _emberVersionInfo.major,
        minor = _emberVersionInfo.minor,
        isGlimmer = _emberVersionInfo.isGlimmer;


  let getMutValue;

  if (isGlimmer) {
    // The module location changed since v3.2
    let libPath = major > 3 || major == 3 && minor >= 2 ? 'ember-views/lib/compat/attrs' : 'ember-views/compat/attrs';

    var _Ember$__loader$requi = Ember.__loader.require(libPath);

    const MUTABLE_CELL = _Ember$__loader$requi.MUTABLE_CELL;

    getMutValue = value => {
      if (value && value[MUTABLE_CELL]) {
        return value.value;
      } else {
        return value;
      }
    };
  } else {
    getMutValue = Ember.__loader.require('ember-htmlbars/hooks/get-value')['default'];
  }

  function getMutableAttributes(attrs) {
    return Object.keys(attrs).reduce((acc, attr) => {
      acc[attr] = getMutValue(attrs[attr]);
      return acc;
    }, {});
  }
});