define('ember-easy-form-extensions/components/form-submission', ['exports', 'ember', 'ember-easy-form-extensions/mixins/components/form-submission-class-name', 'ember-easy-form-extensions/templates/components/form-submission'], function (exports, _ember, _emberEasyFormExtensionsMixinsComponentsFormSubmissionClassName, _emberEasyFormExtensionsTemplatesComponentsFormSubmission) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_emberEasyFormExtensionsMixinsComponentsFormSubmissionClassName['default'], {

    /* Options */

    className: 'form-submission',

    cancel: true,
    cancelAction: 'cancel',
    cancelText: 'Cancel',

    'delete': false,
    deleteAction: 'delete',
    deleteText: 'Delete',

    save: true,
    saveAction: 'save',
    saveText: 'Save',
    layout: _emberEasyFormExtensionsTemplatesComponentsFormSubmission['default'],

    /* Properties */

    classNameBindings: ['className'],
    formIsSubmitted: computed.oneWay('formController.formIsSubmitted'),

    formController: computed(function () {
      var hasFormController = this.nearestWithProperty('formController');

      return hasFormController.get('formController');
    }),

    /* Actions */

    actions: {

      cancel: function cancel() {
        this.sendAction('cancelAction');
      },

      'delete': function _delete() {
        this.sendAction('deleteAction');
      },

      save: function save() {
        this.sendAction('saveAction');
      }

    }

  });
});