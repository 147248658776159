define('ember-cli-react/components/react-component', ['exports', 'react', 'react-dom', 'ember-cli-react/components/react-component/yield-wrapper', 'ember-cli-react/utils/get-mutable-attributes', 'ember-cli-react/utils/has-block', 'ember-cli-react/utils/lookup-factory'], function (exports, _react, _reactDom, _yieldWrapper, _getMutableAttributes, _hasBlock, _lookupFactory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const get = Ember.get;


  const ReactComponent = Ember.Component.extend({
    /**
      The React component that this Ember component should wrap.
       @property reactComponent
      @type React.Component | Function | String
      @default null
     */
    reactComponent: Ember.computed.reads('_reactComponent'),

    didRender: function didRender() {
      this.renderReactComponent();
    },

    renderReactComponent() {
      const componentClassOrName = get(this, 'reactComponent');
      let componentClass;

      if (Ember.typeOf(componentClassOrName) === 'string') {
        componentClass = (0, _lookupFactory.default)(this, `react-component:${componentClassOrName}`);

        // Set `displayName` so that it is visible in React devtools
        componentClass.displayName = Ember.String.classify(componentClassOrName);
      } else {
        componentClass = componentClassOrName;
      }

      if (!componentClass) {
        throw new Error(`Could not find react component : ${componentClassOrName}`);
      }

      const props = (0, _getMutableAttributes.default)(get(this, 'attrs'));

      // Determine the children
      // If there is already `children` in `props`, we just pass it down (it can be function).
      // Otherwise we need to wrap the current `childNodes` inside a React component.
      // It is important that `childNodes` are reconstructed with `[...childNodes]` because
      // it is a `NodeList`-type object instead of Array in the first place.
      // Without reconstructing, `childNodes` will include the React component itself when
      // `componentDidMount` hook is triggerred.
      let children = props.children;
      if (!children) {
        const childNodes = get(this, 'element.childNodes');

        // In Ember 2.8, an empty comment node is still created for non-block form
        // component. This behavior breaks any component that does not expect
        // children to exist.
        // We can safely assume that there is no child node if:
        // - The component is not in block form
        // - There is no child node (of course)

        // For other cases, we need to create a YieldWrapper to hold the nodes
        if ((0, _hasBlock.default)(this) && childNodes.length > 0) {
          children = [_react.default.createElement(_yieldWrapper.default, {
            key: get(this, 'elementId'),
            nodes: [...childNodes]
          })];
        }
      }

      _reactDom.default.render(_react.default.createElement(componentClass, props, children), get(this, 'element'));
    },

    willDestroyElement: function willDestroyElement() {
      _reactDom.default.unmountComponentAtNode(get(this, 'element'));
    }
  });

  ReactComponent.reopenClass({
    // Some versions of Ember override positional param value to undefined when
    // a subclass is created using `Ember.extend({ reactComponent: foo })` so
    // instead store this value in a separate property.
    positionalParams: ['_reactComponent']
  });

  exports.default = ReactComponent;
});