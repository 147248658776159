define('ember-easy-form-extensions/initializers/easy-form-extensions', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize() {

    _ember['default'].Checkbox.reopen({
      attributeBindings: ['checked:aria-checked']
    });

    _ember['default'].TextArea.reopen({
      attributeBindings: ['invalid:aria-invalid', 'required:aria-required']
    });

    _ember['default'].TextField.reopen({
      attributeBindings: ['invalid:aria-invalid', 'required:aria-required']
    });
  }

  exports['default'] = {
    name: 'easy-form-extensions',
    initialize: initialize
  };
});