define('ember-click-outside/mixins/click-outside', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = _ember.default.$;


  const {
    computed
  } = _ember.default;
  const bound = function bound(fnName) {
    return computed(fnName, function () {
      let fn = this.get(fnName);
      if (fn) {
        // https://github.com/zeppelin/ember-click-outside/issues/1
        return fn.bind(this);
      }
    });
  };
  const supportsTouchEvents = () => {
    return 'ontouchstart' in window || window.navigator.msMaxTouchPoints;
  };

  exports.default = _ember.default.Mixin.create({
    clickOutside() {},
    clickHandler: bound('outsideClickHandler'),

    didInsertElement() {
      this._super(...arguments);

      if (!supportsTouchEvents()) {
        return;
      }

      $('body').css('cursor', 'pointer');
    },

    willDestroyElement() {
      this._super(...arguments);

      if (!supportsTouchEvents()) {
        return;
      }

      $('body').css('cursor', '');
    },

    outsideClickHandler(e) {
      const element = this.get('element');
      const $target = $(e.target);
      const isInside = $target.closest(element).length === 1;

      if (!isInside) {
        this.clickOutside(e);
      }
    },

    addClickOutsideListener() {
      const clickHandler = this.get('clickHandler');
      $(window).on('click', clickHandler);
    },

    removeClickOutsideListener() {
      const clickHandler = this.get('clickHandler');
      $(window).off('click', clickHandler);
    }
  });
});