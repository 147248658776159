define("ember-cli-segment/services/segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _disabled: false,
    _defaultPageTrackDisabled: false,
    _defaultIdentifyUserDisabled: false,
    _calledPageTrack: false,

    init() {
      this._super(...arguments);

      const isFastBoot = typeof FastBoot !== 'undefined';

      if (!this.hasAnalytics() && this.config && this.config.environment !== 'test' && !isFastBoot) {
        (false && Ember.warn('Segment is not loaded yet (window.analytics)', false, {
          id: 'ember-cli-segment.analytics-not-loaded'
        }));
      }

      if (this.config && this.config.segment) {
        const {
          defaultPageTrack,
          defaultIdentifyUser,
          enabled
        } = this.config.segment;
        this.set('_defaultPageTrackDisabled', defaultPageTrack === false);
        this.set('_defaultIdentifyUserDisabled', defaultIdentifyUser === false);
        this.set('_disabled', enabled === false);
      }
    },

    hasAnalytics() {
      return !!(window.analytics && typeof window.analytics === 'object');
    },

    isEnabled() {
      return !this.get('_disabled');
    },

    enable() {
      this.set('_disabled', false);
    },

    disable() {
      this.set('_disabled', true);
    },

    isPageTrackEnabled() {
      return !this.get('_defaultPageTrackDisabled');
    },

    enableDefaultPageTrack() {
      this.set('_defaultPageTrackDisabled', false);
    },

    disableDefaultPageTrack() {
      this.set('_defaultPageTrackDisabled', true);
    },

    isIdentifyUserEnabled() {
      return !this.get('_defaultIdentifyUserDisabled');
    },

    enableDefaultIdentifyUser() {
      this.set('_defaultIdentifyUserDisabled', false);
    },

    disableDefaultIdentifyUser() {
      this.set('_defaultIdentifyUserDisabled', true);
    },

    log() {
      if (this.config && this.config.segment && this.config.segment.LOG_EVENT_TRACKING) {
        console.info('[Segment] ', arguments); // eslint-disable-line no-console
      }
    },

    getTraits() {
      if (this.isEnabled() && this.hasAnalytics()) {
        this.log('getTraits');
        return window.analytics.user().traits();
      }
    },

    trackPageView() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.page.apply(this, arguments);
        this.set('_calledPageTrack', true);
        this.log('trackPageView', arguments);
      }
    },

    trackEvent(event, properties, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        this.checkPageTrackCalled();
        window.analytics.track(event, properties, options, callback);
        this.log(event, properties, options);
      }
    },

    identifyUser(userId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.identify(userId, traits, options, callback);
        this.log('identifyUser', traits, options);
      }
    },

    identifyGroup(groupId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.group(groupId, traits, options, callback);
        this.log('identifyGroup', traits, options);
      }
    },

    // reset group, user traits and id's
    reset() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.reset();
        this.log('reset');
      }
    },

    group(groupId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.group(groupId, traits, options, callback);
        this.log('group', traits, options);
      }
    },

    aliasUser(userId, previousId, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.alias(userId, previousId, options, callback);
        this.log('aliasUser', userId, previousId, options);
      }
    },

    /**
     * Logs warning into console if trackPageView method wasn't called before tracking event
     *
     * @see https://segment.com/docs/sources/website/analytics.js/#page
     */
    checkPageTrackCalled() {
      (false && Ember.warn('[ember-cli-segment] You should call trackPageView at least once ' + 'before tracking events: ' + 'https://segment.com/docs/sources/website/analytics.js/#page', this.get('_calledPageTrack'), {
        id: 'ember-cli-segment.must-call-page'
      }));
    }

  });

  _exports.default = _default;
});