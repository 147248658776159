define('ember-cli-react/utils/lookup-factory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = lookupFactory;
  const getOwner = Ember.getOwner;


  // See http://emberjs.com/deprecations/v2.x/#toc_injected-container-access
  function lookupFactory(object, name) {
    if (getOwner) {
      return getOwner(object).resolveRegistration(name);
    } else {
      return object.get('container').lookupFactory(name);
    }
  }
});