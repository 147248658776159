define('ember-easy-form-extensions/mixins/routes/dirty-record-handler', ['exports', 'ember', 'ember-easy-form-extensions/utils/default-for'], function (exports, _ember, _emberEasyFormExtensionsUtilsDefaultFor) {
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Mixin.create({

    /**
    If the model `isDirty` (i.e. some data has been temporarily
    changed) rollback the record to the most recent clean version
    in the store. If there is no clean version in the store,
    delete the record.
     TODO - add ability to stop transition and ask for transition confirm
     @method rollbackifDirty
    */

    actions: {
      willTransition: function willTransition() {
        var model = this.get('controller.model');
        if (model && model.get('hasDirtyAttributes')) {
          if (model.get('id')) {
            model.rollbackAttributes();
          } else {
            model.deleteRecord();
          }
        }
        return true;
      }
    }

  });
});
/**
Undo changes in the store made to an existing but not-saved
model. This should be mixed into 'edit' routes like
`CampaignEditRoute` and `BusinessEditRoute`. All non-persisted
changes to the model are undone.

@class DireyRecordHandler
@submodule mixins
*/